// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import baseUserContextPage from "../baseUserContextPage";
import axios, { AxiosPromise } from "axios";
import { pagedList, sortDirection } from "@/models/pagedList";
import { orderModel } from "@/models/orderModel";

@Component({})
export default class ordersPage extends baseUserContextPage {
  orders: pagedList<orderModel> = new pagedList();
  isLoading: boolean = false;
  searchParams: any = {
    pageSize: 20,
    pageIndex: 0,
    orderField: "createDate",
    direction: sortDirection.DESC,
    search: null,
    paymentStateIn: []
  };
  get showDestination(): boolean {
    return this.isUserB2BAdmim || this.isUserRefEmployee || this.isUserEmployee;
  }
  updateQueryParams(): void {
    var instance = this;
    instance.$router.push({
      name: "orders",
      query: instance.getQueryParams()
    });
    this.fetch();
  }
  getQueryParams(): any {
    var instance = this;
    return {
      page: instance.searchParams.pageIndex,
      search: instance.searchParams.search,
      states: instance.searchParams.paymentStateIn,
      orderField: instance.searchParams.orderField,
      direction: instance.searchParams.direction
    };
  }
  setQueryParams(params: any) {
    var instance = this;

    instance.searchParams.pageIndex = params.page;
    instance.searchParams.search = params.search;
    instance.searchParams.paymentStateIn = params.states ? params.states : [];
    instance.searchParams.orderField = params.orderField;
    instance.searchParams.direction = params.direction;
  }
  pageChanged(page: number) {
    var instance = this;
    instance.searchParams.pageIndex = page;
    instance.updateQueryParams();
    // this.fetch();
  }
  onSearch() {
    var instance = this;
    instance.searchParams.pageIndex = 0;
    instance.updateQueryParams();
    //instance.fetch();
  }
  onSort(field: string) {
    var instance = this;
    if (instance.searchParams.orderField != field) {
      instance.searchParams.orderField = field;
      instance.searchParams.direction = sortDirection.ASC;
    } else {
      instance.searchParams.direction =
        instance.searchParams.direction == sortDirection.ASC
          ? sortDirection.DESC
          : sortDirection.ASC;
    }
    instance.updateQueryParams();
    //instance.fetch();
  }
  toglePaymentSelected(state: string) {
    var instance = this;
    if (instance.isStateSelcted(state)) {
      instance.searchParams.paymentStateIn = instance.searchParams.paymentStateIn.filter(
        s => s != state
      );
    } else {
      instance.searchParams.paymentStateIn.push(state);
    }
    instance.searchParams.pageIndex = 0;
    instance.updateQueryParams();
    //instance.fetch();
  }
  isPaymentSelcted(state: string): boolean {
    return this.searchParams.paymentStateIn.indexOf(state) != -1;
  }
  isStateSelcted(state: string): boolean {
    return this.searchParams.paymentStateIn.indexOf(state) != -1;
  }
  fetch(): void {
    //${JSON.stringify(instance.orderStateIn)}
    var instance = this;
    instance.isLoading = true;
    axios
      .get<pagedList<orderModel>>("/order/search", {
        params: instance.searchParams
      })
      .then(res => {
        instance.orders = res.data ? res.data : new pagedList();
        instance.isLoading = false;
      })
      .catch(error => {
        console.error(error);
        instance.isLoading = false;
      });
  }
  mounted() {
    var instance = this;
    instance.setQueryParams(instance.$route.query);
    instance.fetch();
  }
}
